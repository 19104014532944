<template>
  <div class="logo">
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 132 128.9" style="enable-background:new 0 0 132 128.9;">
      <g id="b">
        <g>
          <defs>
            <polyline id="SVGID_1_" points="0,0 0,128.9 360,128.9 360,0 			" />
          </defs>
          <clipPath id="SVGID_00000133496255780954688050000011969162306550557084_">
            <use xlink:href="#SVGID_1_" style="overflow:visible;" />
          </clipPath>
          <g style="clip-path:url(#SVGID_00000133496255780954688050000011969162306550557084_);">
            <g id="d">
              <g id="e">
                <path
                  id="f"
                  class="st1"
                  d="M41.9,103.6C20.3,91.1,13,63.5,25.4,41.9s40.1-29,61.6-16.5c1.8,1.1,3.6,2.3,5.3,3.6L47.7,54.8
						l16.7,9.7L38.6,79.4l59.3-14.9l-16.7-9.7l39.1-22.6C102.5,1.4,63.1-9.1,32.2,8.6C1.4,26.4-9.1,65.9,8.6,96.7
						s57.2,41.4,88.1,23.6c9.8-5.7,17.9-13.8,23.6-23.6L103.5,87C91.1,108.6,63.5,116,41.9,103.6L41.9,103.6"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.logo {
  svg {
    width: 104px;
    height: auto;
    max-width: 100%;
    display: block;
    margin: 0 0 20px 0;
  }
  * {
    fill: #fff;
  }
  .st0 {
    clip-path: url(#SVGID_00000130634718909028369520000017514578349726137488_);
  }
  .st1 {
    fill: #fff;
  }
}
</style>
