<template>
  <template v-if="!loading">
    <Form :fields="fields" :onSubmit="submit" />
  </template>
</template>

<script>
import { References } from '@/services/repositories/References';
import Form from '@/components/form/Form';
import MainInfoFields from '../services/MainFields';

export default {
  props: {
    reference: Object
  },
  data() {
    return {
      fields: MainInfoFields,
      loading: true
    };
  },
  mounted() {
    if (this.reference) {
      this.fields.setValuesFromEntity(this.reference);
    } else {
      this.fields.reset();
    }
    this.loading = false;
  },
  methods: {
    submit(values) {
      if (this.reference) {
        values.id = this.reference.id;
      }

      References.save(values).then(response => {
        if (response.result == 'ok') {
          window.eventBus.emit('success-toast', 'Reference uložena.');

          if (!this.reference) {
            window.eventBus.emit('reference-created', response.id);
          }
        } else {
          window.eventBus.emit('error-toast', 'Referenci se nepodařilo uložit!');
        }
      });
    }
  },
  components: {
    Form
  }
};
</script>

<style lang="scss" scoped></style>
