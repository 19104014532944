<template>
  <form v-on:submit.prevent="submit">
    <template v-for="field in fields.get()" :key="field.name">
      <div
        class="form__pair"
        :ref="field.name"
        v-if="
          field.type != types.submit && field.type != types.submitAndRedirect
        "
      >
        <label class="form__label">
          {{ field.label }}
        </label>

        <div
          :class="[
            'form__field-wrapper',
            field.fullWidth ? 'form__field-wrapper--full-width' : null,
          ]"
        >
          <InputText
            :id="field.name"
            v-if="field.type == types.text"
            v-model="values[field.name]"
            @keyup="valuesChanged"
            :disabled="field.disabled ? true : false"
            :placeholder="field.label"
          />

          <Calendar
            :id="field.name"
            v-model="values[field.name]"
            v-if="field.type == types.date"
            dateFormat="dd. mm. yy"
            :showTime="false"
            @keyup="valuesChanged"
            :placeholder="field.label"
            @date-select="dateSelected(field.name)"
          />

          <Calendar
            :id="field.name"
            v-model="values[field.name]"
            v-if="field.type == types.dateTime"
            dateFormat="dd. mm. yy"
            :showTime="true"
            @keyup="valuesChanged"
            :placeholder="field.label"
            @date-select="valuesChanged"
          />

          <TimePicker
            v-if="field.type == types.time"
            :default="field.defaultValue ? field.defaultValue : null"
            :name="field.name"
            @change="timeUpdated"
          />

          <TimeIntervalPicker
            v-if="field.type == types.timeInterval"
            :default="field.defaultValue ? field.defaultValue : null"
            :name="field.name"
            @interval-change="valuesChanged"
          />

          <Password
            :id="field.name"
            v-if="field.type == types.password"
            v-model="values[field.name]"
            @keyup="valuesChanged"
            :placeholder="field.label"
          />

          <Checkbox
            :id="field.name"
            v-if="field.type == types.checkbox"
            v-model="values[field.name]"
            :binary="true"
            @change="valuesChanged"
            :placeholder="field.label"
          />

          <Rating
            v-if="field.type == types.rating"
            v-model="values[field.name]"
            :stars="field.maxStars"
            :cancel="false"
            @change="valuesChanged"
          />

          <Textarea
            v-if="field.type == types.textarea"
            v-model="values[field.name]"
            :autoResize="true"
            rows="5"
            @keyup="valuesChanged"
            :placeholder="field.placeholder ? field.placeholder : field.label"
          />

          <InputNumber
            v-if="field.type == types.number"
            v-model="values[field.name]"
            :min="field.min || null"
            :max="field.max || null"
            :format="field.format == false ? false : true"
            @input="numberUpdated($event, field)"
            :placeholder="field.placeholder ? field.placeholder : field.label"
          />

          <InputNumber
            v-if="field.type == types.money"
            mode="currency"
            :currency="field.currency ? field.currency : 'CZK'"
            locale="cs-CZ"
            @input="numberUpdated($event, field)"
            v-model="values[field.name]"
            :placeholder="field.label"
            :maxFractionDigits="0"
          />

          <ImageCrop
            v-if="field.type == types.imageCrop"
            :aspectRatio="field.ratio || null"
            :uploader="field.upload"
            :inputName="field.name"
            :defaultImage="field.defaultValue || null"
            :display="field.display || null"
            :ref="field.name"
            @imageFinished="imageEditFinished"
          />

          <IconSelect
            v-if="field.type == types.iconSelect"
            :name="field.name"
            :value="field.value"
            :default="field.defaultValue ?? 'star'"
            :ref="field.name"
            @iconSelected="iconSelected"
          />

          <ColorPicker
            v-if="field.type == types.color"
            :name="field.name"
            :value="field.value"
            :ref="field.name"
            @colorPicked="colorPicked"
          />

          <Dropdown
            v-if="field.type == types.dropdown"
            :id="field.name"
            v-model="values[field.name]"
            @change="valuesChanged"
            :options="field.options"
            :optionLabel="field.optionLabel ? field.optionLabel : 'name'"
            :optionValue="field.optionValue ? field.optionValue : 'id'"
            :placeholder="field.placeholder"
            :editable="field.editable || false"
            :showClear="field.showClear ? field.showClear : false"
            :disabled="field.disabled ? field.disabled : false"
          />

          <Editor
            v-if="field.type == types.wysiwyg"
            :id="field.name"
            v-model="values[field.name]"
            @text-change="valuesChanged"
            class="form__wysiwyg"
            :placeholder="field.label"
          >
            <template #toolbar>
              <span class="ql-formats">
                <button class="ql-bold" v-tooltip.bottom="'Tunčné'"></button>
                <button class="ql-italic" v-tooltip.bottom="'Kurzíva'"></button>
              </span>
              <span class="ql-formats">
                <button class="ql-link" v-tooltip.bottom="'Odkaz'"></button>
                <button
                  class="ql-list"
                  value="ordered"
                  v-tooltip.bottom="'Seznam'"
                ></button>
                <button
                  class="ql-list"
                  value="bullet"
                  v-tooltip.bottom="'Seznam'"
                ></button>
              </span>
              <span class="ql-formats">
                <select class="ql-header">
                  <option value="2">Nadpis 2</option>
                  <option value="3">Nadpis 3</option>
                  <option value="4">Nadpis 4</option>
                </select>
              </span>
            </template>
          </Editor>
        </div>

        <div
          class="form__field-save"
          v-if="showSaveNextToFields && showSaveFor[field.name]"
        >
          <Button
            label="Uložit"
            @click="submitSingleField(field.name)"
            class="p-button p-button--min-width"
          />
        </div>
      </div>
    </template>

    <div class="form__pair">
      <label class="form__label"> &nbsp; </label>

      <div class="form__field-wrapper form__field-wrapper--full-width">
        <div class="submit">
          <template v-for="field in fields.get()" :key="field.name">
            <Button
              :id="field.name"
              v-if="field.type == types.submit"
              :label="field.text"
              class="p-button p-button-primary"
              @click="submit"
            />

            <Button
              :id="field.name"
              v-if="field.type == types.submitAndRedirect"
              :label="field.text"
              class="p-button p-button-secondary"
              @click="submitAndRedirect(field)"
            />
          </template>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import Calendar from 'primevue/calendar';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Rating from 'primevue/rating';
import Dropdown from 'primevue/dropdown';
import Editor from 'primevue/editor';
import InputNumber from 'primevue/inputnumber';
import Textarea from 'primevue/textarea';
import ImageCrop from './imageCrop/ImageCrop';
import TimePicker from './time/TimePicker';
import TimeIntervalPicker from './time/TimeIntervalPicker';
import IconSelect from './iconSelect/IconSelect.vue';
import ColorPicker from './color/ColorPicker.vue';
import { Validator } from '@/services/form/Validator';
import { FieldTypes } from '@/services/form/FieldTypes';
import { FieldHiding } from '@/services/form/FieldHiding';

import moment from 'moment';

export default {
  props: {
    fields: Object,
    onSubmit: Function,
    showSaveNextToFields: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      types: FieldTypes,
      values: {},
      showSaveFor: [],
    };
  },
  created() {
    window.eventBus.on('field-saved', () => {
      this.valuesChanged();
    });
  },
  mounted() {
    this.resetValues();

    for (var field in this.values) {
      this.showSaveFor[field] = false;
    }
  },
  methods: {
    valuesChanged() {
      for (var field in this.values) {
        this.fields.setValue(field, this.values[field]);

        if (this.fields.get()[field].defaultValue != this.values[field]) {
          this.showSaveFor[field] = true;
        } else {
          this.showSaveFor[field] = false;
        }
      }

      this.toggleFields();
    },
    dateSelected(field) {
      let d = moment(this.values[field]);
      d.set('hour', 10);
      this.values[field] = d.toDate();
      this.valuesChanged();
    },
    numberUpdated(e, field) {
      this.values[field.name] = e.value;
      this.valuesChanged();
    },
    iconSelected(field) {
      this.values[field.fieldName] = field.icon;
      this.valuesChanged();
    },
    colorPicked(field) {
      this.values[field.name] = field.value;
      this.valuesChanged();
    },
    timeUpdated(input) {
      this.values[input.name] = input.value;
      this.valuesChanged();
    },
    submit() {
      let errors = Validator.validate(this.fields.get());

      if (errors.length != 0) {
        errors.forEach((error) => {
          window.eventBus.emit('error-toast', error.message);
        });
      } else if (this.onSubmit) {
        this.onSubmit(this.fields.getValues());
      }
    },
    submitAndRedirect(submitField) {
      let errors = Validator.validate(this.fields.get());

      if (errors.length != 0) {
        errors.forEach((error) => {
          window.eventBus.emit('error-toast', error.message);
        });
      } else if (this.onSubmit) {
        this.onSubmit(this.fields.getValues());
        submitField.onSubmit();
      }
    },
    submitSingleField(name) {
      let errors = Validator.validate([this.fields.get()[name]]);

      if (errors.length != 0) {
        errors.forEach((error) => {
          this.$toast.add({
            severity: 'error',
            summary: error.message,
            life: 3000,
          });
        });
      } else if (this.onSubmit) {
        this.onSubmit(name, this.fields.getValues()[name]);
      }
    },
    imageEditFinished(data) {
      this.values[data.inputName] = data.imageName;
      this.valuesChanged();
    },
    resetValues() {
      this.values = this.fields.getValues();
      this.toggleFields();
    },
    toggleFields() {
      let fields = this.fields.get();

      for (var name in fields) {
        let field = fields[name];

        if (field.hideRules) {
          var targetValue = this.fields.getValues()[field.hideRules.target];

          if (
            (field.hideRules.when == FieldHiding.fieldIsNotEmpty &&
              targetValue != null &&
              targetValue != '') ||
            (field.hideRules.when == FieldHiding.fieldHasValue &&
              targetValue == field.hideRules.value) ||
            (field.hideRules.when == FieldHiding.fieldHasntValue &&
              targetValue != field.hideRules.value)
          ) {
            this.$refs[name].classList.add('form__pair--hidden');
          } else {
            this.$refs[name].classList.remove('form__pair--hidden');
          }
        }
      }
    },
  },
  components: {
    Password,
    Calendar,
    Dropdown,
    Button,
    Rating,
    ImageCrop,
    Editor,
    Textarea,
    InputNumber,
    Checkbox,
    InputText,
    TimeIntervalPicker,
    TimePicker,
    IconSelect,
    ColorPicker,
  },
};
</script>

<style lang="scss" scoped>
.submit {
  display: flex;
  gap: 10px;
}
</style>
