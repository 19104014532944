<template>
  <div class="page-head">
    <h1>Reference</h1>
    <div class="page-head__actions">
      <Button @click="$router.push({ name: 'references-new' })" label="Přidat novou" icon="pi pi-plus" />
    </div>
  </div>

  <div class="box">
    <DataTable :value="references" dataKey="id" :rowHover="true" :loading="loading" @rowReorder="onRowReorder">
      <template #loading> Načítám reference... </template>
      <template #empty>
        Žádná reference.
      </template>

      <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />

      <Column field="mainImage" class="image__thumb" style="width: 70px;">
        <template #body="{ data }">
          <router-link :to="{ name: 'references-detail', params: { id: data.id } }">
            <img v-if="data.image" :src="data.image.thumb.src" />
          </router-link>
        </template>
      </Column>

      <Column field="name" header="Název">
        <template #body="{ data }">
          <router-link :to="{ name: 'references-detail', params: { id: data.id } }">
            {{ data.name }}
          </router-link>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { References } from '@/services/repositories/References';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';

export default {
  data() {
    return {
      references: [],
      loading: true
    };
  },
  mounted() {
    References.getAll().then(data => {
      this.references = data;
      this.loading = false;
    });
  },
  methods: {
    onRowReorder(event) {
      this.references = event.value;
      let order = [];

      event.value.forEach((point, i) => {
        order.push({ id: point.id, order: i });
      });

      References.saveOrder(order).then(() => {
        window.eventBus.emit('success-toast', 'Pořadí uloženo.');
      });
    }
  },
  components: {
    DataTable,
    Button,
    Column
  }
};
</script>

<style lang="scss" scoped>
.image {
  &__thumb {
    img {
      --size: 50px;
      width: var(--size);
      height: var(--size);
      object-fit: cover;
    }
  }
}
</style>
