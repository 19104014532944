<template>
  <div class="logo">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="360" height="128.92" viewBox="0 0 360 128.92">
      <defs>
        <clipPath id="logo-clip">
          <path d="M0,97.739H360V-31.181H0Z" transform="translate(0 31.181)" fill="none" />
        </clipPath>
      </defs>
      <g transform="translate(0 31.181)">
        <g class="top-text">
          <g transform="translate(0 -31.181)" clip-path="url(#logo-clip)">
            <g transform="translate(148.67 32.287)">
              <path
                d="M0,10.081a13.131,13.131,0,0,1,13.247-13.3c6.954,0,12.428,5.933,12.428,13.193a22.768,22.768,0,0,1-.153,2.456H6.954c.819,3.531,3.734,5.218,7.111,5.218a12.71,12.71,0,0,0,6.9-1.993l2.555,4.908a17.251,17.251,0,0,1-9.509,2.811C6.545,23.377,0,18.519,0,10.081m19.23-2.2A6.177,6.177,0,0,0,13.04,2.51,6.416,6.416,0,0,0,6.851,7.881Z"
                transform="translate(0 3.216)"
                fill="#fff"
              />
            </g>
          </g>
        </g>
        <g class="top-text">
          <path d="M43.257-25.2h6.851V8.448H43.257Z" transform="translate(135.592 18.738)" fill="#fff" />
        </g>
        <g class="top-text" transform="translate(0 -31.181)" clip-path="url(#logo-clip)">
          <g transform="translate(190.148 32.287)">
            <path
              d="M0,10.081a13.131,13.131,0,0,1,13.247-13.3c6.954,0,12.428,5.933,12.428,13.193a22.768,22.768,0,0,1-.153,2.456H6.954c.819,3.531,3.734,5.218,7.111,5.218a12.71,12.71,0,0,0,6.9-1.993l2.555,4.908a17.251,17.251,0,0,1-9.509,2.811C6.545,23.377,0,18.519,0,10.081m19.23-2.2A6.176,6.176,0,0,0,13.045,2.51,6.417,6.417,0,0,0,6.851,7.881Z"
              transform="translate(0 3.216)"
              fill="#fff"
            />
          </g>
          <g transform="translate(219.557 32.287)">
            <path
              d="M0,10.081a13.387,13.387,0,0,1,13.656-13.3A14.109,14.109,0,0,1,22.806.059l-4.139,4.8a7.037,7.037,0,0,0-4.858-1.737,6.663,6.663,0,0,0-6.855,6.954,6.658,6.658,0,0,0,7.062,6.954,8.219,8.219,0,0,0,5.573-2.1l3.63,5.114a14.776,14.776,0,0,1-9.563,3.324A13.384,13.384,0,0,1,0,10.081"
              transform="translate(0 3.216)"
              fill="#fff"
            />
          </g>
          <g transform="translate(244.309 26.354)">
            <path d="M2.831,18.03V6.218H-.9V.694H2.831V-4.214L9.681-5.752V.694h7.062V6.218H9.681V17.927c0,2.2,1.075,2.915,2.307,2.915a6.284,6.284,0,0,0,3.27-1.071L17.2,24.831a11.771,11.771,0,0,1-6.5,1.943c-5.114,0-7.876-2.964-7.876-8.745" transform="translate(0.903 5.752)" fill="#fff" />
          </g>
          <g transform="translate(265.789 32.287)">
            <path d="M0,.389H6.752V5.3c.715-2.1,3.324-5.42,8.947-5.42V7.343c-5.929,0-8.848,2.708-8.848,8.8v9.82H0Z" transform="translate(0 0.124)" fill="#fff" />
          </g>
          <g transform="translate(284.559 21.546)">
            <path d="M0,3.1A4.1,4.1,0,0,1,4.044-.99,4.108,4.108,0,0,1,8.137,3.1,4.069,4.069,0,0,1,0,3.1m.666,7.161H7.517V35.836H.666Z" transform="translate(0 0.99)" fill="#fff" />
          </g>
          <g transform="translate(296.526 32.287)">
            <path
              d="M0,10.081a13.387,13.387,0,0,1,13.656-13.3A14.128,14.128,0,0,1,22.81.059l-4.143,4.8a7.037,7.037,0,0,0-4.858-1.737,6.663,6.663,0,0,0-6.855,6.954,6.658,6.658,0,0,0,7.062,6.954,8.219,8.219,0,0,0,5.573-2.1l3.63,5.114a14.776,14.776,0,0,1-9.563,3.324A13.384,13.384,0,0,1,0,10.081"
              transform="translate(0 3.216)"
              fill="#fff"
            />
          </g>
          <g transform="translate(321.692 32.287)">
            <path
              d="M0,10.081c0-7.364,4.5-13.3,11.556-13.3a10,10,0,0,1,7.98,3.63V-2.7h6.7V22.865h-6.7V19.8c-1.79,2.456-5.011,3.581-7.98,3.581C4.5,23.377,0,17.444,0,10.081m19.383,0c0-3.99-2.559-6.954-6.086-6.954-3.684,0-6.342,2.964-6.342,6.954,0,4.039,2.659,6.954,6.342,6.954,3.527,0,6.086-2.861,6.086-6.954"
              transform="translate(0 3.216)"
              fill="#fff"
            />
          </g>
        </g>
        <g class="top-text">
          <path d="M85.413,8.452h6.855V-25.2H85.413Z" transform="translate(267.732 18.738)" fill="#fff" />
        </g>
        <g id="Group_40" data-name="Group 40" transform="translate(0 -31.181)" clip-path="url(#clip-path)">
          <g id="Group_32" data-name="Group 32" transform="translate(149.233 71.056)">
            <path
              id="Path_34"
              data-name="Path 34"
              d="M0,16.362l.972-1.534a10.276,10.276,0,0,0,8.182,3.527c4.709,0,7.62-2.353,7.62-5.164,0-3.477-3.527-4.345-7.058-5.218-4.4-1.021-8.695-2.146-8.695-6.648,0-4.039,4.3-6.545,8.236-6.545A11.554,11.554,0,0,1,17.08-2.458L16.162-.924a10.244,10.244,0,0,0-6.9-2.4c-3.324,0-6.239,2.047-6.239,4.651,0,3.275,3.527,4.039,7.107,4.808,4.3.972,8.645,2.4,8.645,7.058,0,4.5-4.5,7.058-9.617,7.058A11.665,11.665,0,0,1,0,16.362"
              transform="translate(0 5.22)"
              fill="#ff2d3d"
            />
          </g>
          <g id="Group_33" data-name="Group 33" transform="translate(173.067 71.055)">
            <path
              id="Path_35"
              data-name="Path 35"
              d="M0,9.654C0,2.5,5.218-3.08,11.965-3.08,18.515-3.08,23.629,2.5,23.629,9.6c0,.36-.05.715-.05.819H2.047c.36,5.929,4.8,10.175,10.328,10.175a11.692,11.692,0,0,0,8.133-3.171l1.021,1.435a13.626,13.626,0,0,1-9.154,3.527A12.343,12.343,0,0,1,0,9.654m21.632-.819C21.376,3.109,17.183-1.29,11.916-1.29c-5.371,0-9.563,4.3-9.869,10.126Z"
              transform="translate(0 3.08)"
              fill="#ff2d3d"
            />
          </g>
          <g id="Group_34" data-name="Group 34" transform="translate(202.934 71.054)">
            <path id="Path_36" data-name="Path 36" d="M0,.389H1.943V5.966A10.573,10.573,0,0,1,12.015-.124V1.923A9.756,9.756,0,0,0,1.943,11.9V24.836H0Z" transform="translate(0 0.124)" fill="#ff2d3d" />
          </g>
          <g id="Group_35" data-name="Group 35" transform="translate(218.448 71.566)">
            <path id="Path_37" data-name="Path 37" d="M0,0H1.993L11.2,19.333A25.052,25.052,0,0,1,12.325,22.3a25.052,25.052,0,0,1,1.125-2.964L22.6,0h2L13.04,24.7H11.606Z" fill="#ff2d3d" />
          </g>
          <g id="Group_36" data-name="Group 36" transform="translate(248.385 63.079)">
            <path id="Path_38" data-name="Path 38" d="M0,1.2A1.592,1.592,0,0,1,1.584-.383,1.636,1.636,0,0,1,3.221,1.2,1.646,1.646,0,0,1,1.584,2.838,1.6,1.6,0,0,1,0,1.2m.612,6.9H2.555V32.553H.612Z" transform="translate(0 0.383)" fill="#ff2d3d" />
          </g>
          <g id="Group_37" data-name="Group 37" transform="translate(257.129 71.055)">
            <path
              id="Path_39"
              data-name="Path 39"
              d="M0,9.654A12.579,12.579,0,0,1,12.734-3.08,12.886,12.886,0,0,1,21.479.244L20.2,1.675a10.758,10.758,0,0,0-7.467-2.861A10.557,10.557,0,0,0,2.047,9.654,10.574,10.574,0,0,0,12.784,20.5a10.782,10.782,0,0,0,8.083-3.481L22.145,18.4a12.782,12.782,0,0,1-9.41,3.99A12.577,12.577,0,0,1,0,9.654"
              transform="translate(0 3.08)"
              fill="#ff2d3d"
            />
          </g>
          <g id="Group_38" data-name="Group 38" transform="translate(283.161 71.055)">
            <path
              id="Path_40"
              data-name="Path 40"
              d="M0,9.654C0,2.5,5.218-3.08,11.965-3.08,18.515-3.08,23.629,2.5,23.629,9.6c0,.36-.05.715-.05.819H2.047C2.4,16.352,6.855,20.6,12.375,20.6a11.692,11.692,0,0,0,8.133-3.171l1.025,1.435a13.629,13.629,0,0,1-9.158,3.527A12.343,12.343,0,0,1,0,9.654m21.632-.819C21.38,3.109,17.183-1.29,11.916-1.29c-5.371,0-9.563,4.3-9.869,10.126Z"
              transform="translate(0 3.08)"
              fill="#ff2d3d"
            />
          </g>
          <g id="Group_39" data-name="Group 39" transform="translate(0 -0.001)">
            <path
              id="Path_41"
              data-name="Path 41"
              d="M31.768,78.49A45.113,45.113,0,1,1,82.234,3.973L37.581,29.752l16.741,9.667L28.5,54.328l59.31-14.909L71.071,29.752,110.147,7.19a64.456,64.456,0,1,0-.008,64.454L93.394,61.977A45.112,45.112,0,0,1,31.768,78.49"
              transform="translate(10.135 25.04)"
              fill="#ff2d3d"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
svg {
  width: 272px;
}
</style>
