<template>
  <div class="cropper">
    <div class="cropper__grid">
      <div class="image-preview" v-if="uploadedImage">
        <img :src="uploadedImage.src" />
      </div>

      <div class="image-preview" v-else-if="defaultImage">
        <img :src="defaultImage.src" />
      </div>

      <div class="actions">
        <Button
          @click="openSelectDialog"
          label="Vybrat ze zařízení"
          class="p-button-secondary"
        />
        <Button
          @click="remove"
          label="Odstranit"
          class="p-button-secondary"
          v-if="false && '@todo'"
        />
      </div>
    </div>

    <input
      ref="input"
      type="file"
      @change="onFileSelected"
      class="cropper__input"
    />
    <EditorDialog
      :isOpen="isEditorDialogOpen"
      :aspectRatio="aspectRatio"
      :inputName="inputName"
      ref="cropper"
    />
  </div>
</template>

<script>
import Button from 'primevue/button';
import EditorDialog from './EditorDialog.vue';

export default {
  props: {
    aspectRatio: {
      type: Number,
      default: null,
    },
    defaultImage: {
      type: Object,
      default: null,
    },
    uploader: {
      type: Function,
      default: null,
    },
    remover: {
      type: Function,
      default: null,
    },
    inputName: {
      type: String,
    },
  },
  data() {
    return {
      isEditorDialogOpen: false,
      selectedImage: null,
      uploadedImage: null,
    };
  },
  mounted() {
    if (this.defaultImage) {
      this.$emit('image-finished', {
        inputName: this.inputName,
        imageName: this.defaultImage.fileName,
      });
    }

    window.eventBus.on('image-cropper-cancel', (data) => {
      if (data.cropper == this.$refs.cropper) {
        this.isEditorDialogOpen = false;
      }
    });

    window.eventBus.on('image-cropper-cropped', (data) => {
      if (data.cropper == this.$refs.cropper) {
        this.isEditorDialogOpen = false;

        if (this.uploader) {
          this.uploader(data.imageBlob).then((result) => {
            this.uploadedImage = result;

            this.$emit('image-finished', {
              inputName: this.inputName,
              imageName: this.uploadedImage.image,
            });
          });
        }
      }
    });
  },
  unmounted() {
    window.eventBus.off('image-cropper-cancel');
    window.eventBus.off('image-cropper-cropped');
  },
  methods: {
    openSelectDialog() {
      this.$refs.input.click();
    },
    onFileSelected(e) {
      this.selectedImage = e.target.files[0];

      if (!this.selectedImage) {
        return;
      }

      if (typeof FileReader === 'function') {
        this.isEditorDialogOpen = true;

        const reader = new FileReader();
        reader.onload = (event) => {
          this.$refs.cropper.init(event.target.result, this.selectedImage.type);
        };
        reader.readAsDataURL(this.selectedImage);
      } else {
        window.eventBus.emit(
          'error-toast',
          'Váš prohlížeč bohužel neposkytuje funkce potřebné pro editaci obrázku.'
        );
      }
    },
    remove() {},
  },
  components: {
    EditorDialog,
    Button,
  },
};
</script>

<style lang="scss" scoped>
.cropper {
  &__grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
  }
  &__input {
    display: none;
  }
}
.actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.image-preview {
  max-width: 150px;
  max-height: 150px;
  border-radius: 10px;
  img {
    border-radius: 10px;
    max-width: 100%;
    max-height: 100%;
    object-fit: fill;
  }
}
</style>
