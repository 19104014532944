export default [
  {
    text: 'Reference',
    to: 'references-list',
    icon: 'houseBuilding',
  },
  {
    text: 'Uživatelé',
    to: 'accounts-list',
    icon: 'users',
  }
];
