<template>
  <div class="cropper-editor-dialog">
    <Dialog
      :visible="isOpen"
      :modal="true"
      :showHeader="false"
      :closeOnEscape="true"
    >
      <VueCropper :src="imageBlob" ref="cropper" :aspect-ratio="aspectRatio" />

      <template #footer>
        <Button @click="cancel" label="Storno" class="p-button-secondary" />
        <Button @click="finish" label="Hotovo" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import 'cropperjs/dist/cropper.css';

export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    aspectRatio: {
      type: Number,
      default: null,
    },
    inputName: {
      type: String,
    },
  },
  data() {
    return {
      imageBlob: null,
      imageMimeType: null,
    };
  },
  methods: {
    init(imageBlob, imageMimeType) {
      (this.imageBlob = imageBlob), (this.imageMimeType = imageMimeType);
      this.$refs.cropper.replace(this.imageBlob);
    },
    cancel() {
      window.eventBus.emit('image-cropper-cancel', {
        cropper: this,
        inputName: this.inputName,
      });
    },
    finish() {
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        window.eventBus.emit('image-cropper-cropped', {
          cropper: this,
          imageBlob: blob,
        });
      }, this.imageMimeType);
    },
  },
  components: {
    VueCropper,
    Dialog,
    Button,
  },
};
</script>

<style lang="scss" scoped></style>
