<template>
  <Loading v-if="loading" text="Načítám reference..." />
  <template v-else>
    <div class="page-head">
      <h1 v-if="reference">
        Reference:
        <span class="ft">
          {{ reference.name }}
        </span>
      </h1>

      <h1 v-if="!reference">Nová reference</h1>

      <div class="page-head__actions">
        <Button
          label="Zpět"
          @click="$router.push({ name: 'references-list' })"
          class="p-button-secondary"
          icon="pi pi-arrow-left"
        />
        <Button
          label="Smazat"
          @click="remove"
          v-if="reference"
          class="p-button-secondary"
          icon="pi pi-trash"
        />
      </div>
    </div>

    <div class="box">
      <MainInfoForm :reference="reference" />
    </div>
  </template>
</template>

<script>
import { References } from '@/services/repositories/References';
import MainInfoForm from '../components/MainInfoForm';
import Button from 'primevue/button';
import Loading from '@/components/Loading.vue';

export default {
  data() {
    return {
      reference: null,
      loading: true
    };
  },
  mounted() {
    this.initData();

    window.eventBus.on('reference-created', data => {
      this.initData(data);
    });
  },
  unmounted() {
    window.eventBus.off('reference-created');
  },
  watch: {
    $route(to, from) {
      if (to.name == from.name) {
        this.initData();
      }
    }
  },
  methods: {
    initData(referenceId) {
      this.loading = true;
      referenceId = referenceId || this.$route.params.id;

      if (referenceId) {
        References.getById(referenceId).then(response => {
          this.reference = response;
          this.loading = false;
          document.title = this.reference.name;
        });
      } else {
        this.loading = false;
        this.reference = null;
        document.title = 'Nová reference';
      }
    },
    remove() {
      this.$confirm.require({
        message: 'Opravdu chcete smazat referenci ' + this.reference.name + '?',
        icon: 'pi pi-exclamation-triangle',
        rejectClass: 'p-button-secondary',
        acceptClass: 'p-button-primary',
        accept: () => {
          References.remove(this.reference).then(() => {
            this.$router.push({ name: 'references-list' });
            window.eventBus.emit('success-toast', 'Reference ' + this.reference.name + ' byla odstraněna.');
          });
        }
      });
    }
  },
  components: {
    MainInfoForm,
    Loading,
    Button
  }
};
</script>

<style lang="scss" scoped></style>
