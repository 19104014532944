import { API } from '@/services/API';

const BASE_URL = 'references/';

const References = {
  getAll: async function() {
    const url = BASE_URL + 'get-all';
    return API.get(url);
  },

  getById: async function(id) {
    return API.get(BASE_URL + 'get-by-id/' + parseInt(id));
  },

  remove: async function(home) {
    return API.get(BASE_URL + 'remove/' + parseInt(home.id));
  },

  save: async function(data) {
    return API.post(BASE_URL + 'save', data);
  },

  saveOrder: function(data) {
    return API.post(BASE_URL + 'save-order', data);
  },

  uploadImage: async function (image) {
    let formData = new FormData();
    formData.append('image', image);

    return API.post(BASE_URL + 'save-image/', formData);
  },
};

export { References };
