import { Rules } from '@/services/form/Rules';
import { FieldTypes } from '@/services/form/FieldTypes';
import { References } from '@/services/repositories/References';

export default {
  fields: {
    name: {
      name: 'name',
      label: 'Název',
      value: null,
      defaultValue: '',
      type: FieldTypes.text,
      fullWidth: true,
      rules: [
        {
          type: Rules.required,
          message: 'Zvolte název.'
        },
        {
          type: Rules.maxLength,
          length: 150,
          message: 'Maxímální délka názvu je 150 znaků.',
        },
      ]
    },
    perex: {
      name: 'perex',
      label: 'Perex',
      value: null,
      defaultValue: '',
      type: FieldTypes.textarea,
      fullWidth: true,
      rules: [
        {
          type: Rules.maxLength,
          length: 500,
          message: 'Maxímální délka perexu je 500 znaků.',
        },
      ],
    },
    text: {
      name: 'text',
      label: 'Text',
      value: null,
      defaultValue: '',
      type: FieldTypes.editor,
      fullWidth: true
    },
    image: {
      value: null,
      defaultValue: null,
      name: 'image',
      type: FieldTypes.imageCrop,
      label: 'Obrázek',
      ratio: 11 / 6,
      display: 'square',
      upload: async (data) => {
        return References.uploadImage(data);
      },
    },
    submit: {
      text: 'Uložit',
      name: 'submit',
      type: FieldTypes.submit
    }
  },
  get() {
    return this.fields;
  },
  setValue(field, value) {
    this.fields[field].value = value;
  },
  setValuesFromEntity(reference) {
    this.setValue('name', reference.name);
    this.setValue('perex', reference.perex);
    this.setValue('text', reference.text);

    if (reference.image) {
      this.fields.image.defaultValue = reference.image.thumb;
    }
  },
  getValues() {
    let values = {};

    for (var name in this.fields) {
      let field = this.fields[name];

      if (field.value === 'undefined') {
        continue;
      }

      if (
        field.type == FieldTypes.imageCrop &&
        !(typeof field.value == 'string')
      ) {
        continue;
      }

      values[field.name] = field.value;
    }

    return values;
  },
  reset() {
    for (var name in this.fields) {
      let field = this.fields[name];

      if (field.defaultValue === 'undefined') {
        continue;
      }

      if (field.type == FieldTypes.imageCrop) {
        field.defaultValue = null;
        continue;
      }

      field.value = field.defaultValue;
    }
  }
};
